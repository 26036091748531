"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reactflow_1 = require("reactflow");
function useIsReactFlowContext() {
    try {
        (0, reactflow_1.useReactFlow)();
        return true;
    }
    catch (error) {
        return false;
    }
}
exports.default = useIsReactFlowContext;
