"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) {
    var _a;
    return ({
        table: {
            "& > div": {
                overflow: "auto",
            },
            "& table": (_a = {
                    "& td": {
                        wordBreak: "keep-all",
                    }
                },
                _a[theme.breakpoints.down("xl")] = {
                    "& td": {
                        maxHeight: 45,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                _a),
            marginBottom: 50,
        },
        inlineWrap: {
            display: "flex",
            flexDirection: "row",
        },
        field: {
            marginTop: 10,
        },
        radioButtonContainer: {
            display: "flex",
            gap: "10px",
            flexDirection: "row",
        },
    });
});
