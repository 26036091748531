"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
function useTimelineViews(mode) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var isChronological = location.pathname.includes("chronological");
    var handleTimelineView = (0, react_1.useCallback)(function (v) {
        if (mode === "timeline") {
            if (v === "chronological") {
                navigate("loader");
                setTimeout(function () { return navigate("chronological", { replace: true }); }, 100);
            }
            else {
                var faker = location.pathname.replace(/[^/]*$/, "loader");
                navigate(faker, { replace: true });
                var loc_1 = location.pathname.split("/").slice(0, -1).join("/");
                setTimeout(function () { return navigate(loc_1, { replace: true }); }, 100);
            }
        }
        else if (v === "chronological") {
            var loc = location.pathname.replace(/[^/]*$/, "chronological");
            navigate(loc, { replace: true });
        }
        else {
            var loc = location.pathname.split("/").slice(0, -1).join("/");
            navigate(loc, { replace: true });
        }
    }, []);
    var handleTimelineSlideShow = (0, react_1.useCallback)(function () {
        if (mode === "timeline" && !isChronological) {
            navigate("slideshow");
        }
        else {
            var loc = location.pathname.replace(/[^/]*$/, "slideshow");
            navigate(loc, { replace: true });
        }
    }, []);
    var handleTimelineSnakeView = (0, react_1.useCallback)(function () {
        if (mode === "timeline" && !isChronological) {
            navigate("snake");
        }
        else {
            var loc = location.pathname.replace(/[^/]*$/, "snake");
            navigate(loc, { replace: true });
        }
    }, []);
    var handleTimelineExcelView = (0, react_1.useCallback)(function () {
        if (mode === "timeline" && !isChronological) {
            navigate("excel");
        }
        else {
            var loc = location.pathname.replace(/[^/]*$/, "excel");
            navigate(loc, { replace: true });
        }
    }, []);
    return {
        handleTimelineView: handleTimelineView,
        handleTimelineSlideShow: handleTimelineSlideShow,
        handleTimelineSnakeView: handleTimelineSnakeView,
        handleTimelineExcelView: handleTimelineExcelView,
    };
}
exports.default = useTimelineViews;
