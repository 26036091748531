"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var usePowerPointFitter_1 = require("@hooks/flow/usePowerPointFitter");
var redux_1 = require("@hooks/redux");
var material_1 = require("@mui/material");
var TextBoxes = function (_a) {
    var customer = _a.customer, PPTXView = _a.PPTXView;
    var showTitleOutline = (0, redux_1.useAppSelector)(function (state) { return state.workspace.showTitleOutlineInPPTX; });
    var showBodyOutline = (0, redux_1.useAppSelector)(function (state) { return state.workspace.showBodyOutlineInPPTX; });
    var customerSlideProperties = (0, usePowerPointFitter_1.getCustomerSlidePropertiesInPx)(customer);
    var contentBoxes = customerSlideProperties.contentBoxes, textBoxBorder = customerSlideProperties.textBoxBorder, titleBox = customerSlideProperties.titleBox;
    var customerTextBoxes = [];
    var borderStyles = {
        borderStyle: textBoxBorder.style,
        borderColor: textBoxBorder.color,
        borderWidth: 1,
    };
    var transformToAbsolute = function (box) { return ({
        position: "absolute",
        left: box.x,
        top: box.y,
        width: box.width,
        height: box.height,
    }); };
    if (showTitleOutline) {
        customerTextBoxes.push(react_1.default.createElement(material_1.Box, { key: "title-text-box", sx: __assign(__assign({}, transformToAbsolute(titleBox)), borderStyles) }));
    }
    if (showBodyOutline) {
        if (PPTXView === "structure") {
            customerTextBoxes.push(react_1.default.createElement(material_1.Box, { key: "structure-text-box", sx: __assign(__assign({}, transformToAbsolute(contentBoxes.full)), borderStyles) }));
        }
        if (PPTXView === "standard") {
            customerTextBoxes.push(react_1.default.createElement(material_1.Box, { key: "standard-text-box-left", sx: __assign(__assign({}, transformToAbsolute(contentBoxes.left)), borderStyles) }), react_1.default.createElement(material_1.Box, { key: "standard-text-box-right", sx: __assign(__assign({}, transformToAbsolute(contentBoxes.right)), borderStyles) }));
        }
    }
    return customerTextBoxes;
};
exports.default = (0, react_1.memo)(TextBoxes);
