"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var tagsActions_1 = require("@components/Tags/reducers/tagsActions");
var data_1 = require("@customTypes/data");
var redux_1 = require("@hooks/redux");
var documentActions_1 = require("@pages/Documents/reducers/documentActions");
var personActions_1 = require("@pages/Persons/reducers/personActions");
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var uiActions_1 = require("@redux/actions/uiActions");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
function useTimelineApi() {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var id = (0, constants_1.getId)(location);
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, timelineActions_http_1.getEditable)(user, +id, navigate));
    }, []);
    (0, react_1.useEffect)(function () {
        dispatch(uiActions_1.closeMenuAction);
        dispatch(documentActions_1.resetDocumentValidation);
        dispatch((0, personActions_1.getPersonDropDown)(user, location));
        dispatch((0, documentActions_1.getDocumentDropDown)(user, location));
        dispatch((0, tagsActions_1.getTags)(user, data_1.WhereInApp.timeline));
        return function () {
            dispatch(uiActions_1.openMenuAction);
            dispatch((0, timelineActions_http_1.toggleThumbnails)(false));
            dispatch((0, timelineActions_http_1.clearState)());
        };
    }, [user, location]);
}
exports.default = useTimelineApi;
