"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Colors_1 = require("@enums/Colors");
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var drawerWidth = "40vw";
var flowHeight = "100vh";
var useStyles = (0, mui_1.makeStyles)()(function (theme, _, classes) {
    var _a, _b;
    return ({
        table: {
            "& table": (_a = {
                    "& td": {
                        wordBreak: "keep-all",
                    }
                },
                _a[theme.breakpoints.down("lg")] = {
                    "& td": {
                        maxHeight: 45,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                _a),
        },
        root: (_b = {
                height: flowHeight,
                zIndex: 1,
                flexGrow: 1,
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                })
            },
            _b[".".concat(classes.pub)] = {
                marginRight: "auto",
                transition: "none",
            },
            _b),
        pub: {},
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        dragger: {
            width: "10px",
            cursor: "ew-resize",
            padding: 1,
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            zIndex: 100,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        lottie: {
            height: 150,
        },
        racoonAndText: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        editorToolbar: {
            position: "relative",
            zIndex: 1000,
            backgroundColor: Colors_1.otherGenericColors.panel,
            top: -100,
            left: 10,
            p: 0,
            justifyContent: "center",
            display: "flex",
            width: "60%",
            maxWidth: "660px",
            "-webkit-transition": "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            "-moz-transition": "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            "-o-transition": "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transition: "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            visibility: "hidden",
            opacity: 0,
        },
        editorToolbarOpen: {
            "-webkit-transition": "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            "-moz-transition": "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            "-o-transition": "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transition: "top 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
            top: 70,
            visibility: "visible",
            opacity: 1,
        },
        flexGrid: {
            display: "flex",
        },
        flexGridPaper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
        },
        chartTooltip: {
            "& > div": {
                "& > table": { margin: 0 },
            },
        },
        boardsBody: {
            width: "100%",
            height: "calc(100vh - 146px)",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
        },
        boardBody: __assign({ overflowY: "auto", height: "calc(100vh - 170px)", padding: theme.spacing(1) }, (0, styles_1.JuristicScroll)(theme)),
    });
});
exports.default = useStyles;
