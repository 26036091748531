"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Borrowed from https://stackoverflow.com/a/8241071
function alphabetize(n, capitalize) {
    if (capitalize === void 0) { capitalize = false; }
    var ordA = "a".charCodeAt(0);
    var ordZ = "z".charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while (n >= 0) {
        s = String.fromCharCode((n % len) + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return capitalize ? s.toUpperCase() : s;
}
exports.default = alphabetize;
