"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@customTypes/styles");
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
    },
    object: {
        width: 300,
    },
    midObject: {
        width: 200,
    },
    shortObject: {
        width: 100,
    },
    textField: {
        marginTop: theme.spacing(0.625),
        marginBottom: theme.spacing(0.625),
    },
    widthTransition: {
        "-webkit-transition": "width 0.2s ease-in-out",
        "-moz-transition": "width 0.2s ease-in-out",
        "-o-transition": "width 0.2s ease-in-out",
        transition: "width 0.2s ease-in-out",
    },
    heightTransition: {
        "-webkit-transition": "height 0.2s ease-in-out",
        "-moz-transition": "height 0.2s ease-in-out",
        "-o-transition": "height 0.2s ease-in-out",
        transition: "height 0.2s ease-in-out",
    },
    scrollContainer: __assign(__assign({ paddingTop: 2, maxHeight: 250, overflowY: "auto" }, (0, styles_1.JuristicScroll)(theme, { size: 0.2 })), { display: "flex", flexDirection: "column" }),
}); });
