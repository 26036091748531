"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var drawerWidth = "40vw";
var flowHeight = "100vh";
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a;
    return ({
        table: {
            "& > div": {
                overflow: "auto",
            },
            "& table": (_a = {
                    "& td": {
                        wordBreak: "keep-all",
                    }
                },
                _a[theme.breakpoints.down("xl")] = {
                    "& td": {
                        maxHeight: 45,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                _a),
            marginBottom: 50,
        },
        root: {
            height: flowHeight,
            zIndex: 1,
            width: "100%",
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: "calc(-".concat(drawerWidth, ")"),
        },
        designContainer: {
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            right: "10px",
            top: "10px",
            bottom: "10px",
            zIndex: 1000,
            justifyContent: "space-between",
            gap: "10px",
            pointerEvents: "none",
        },
        iconsContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        horDividerNoMargin: {
            height: 1,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        designFlexColumn: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
        },
        colorPickerPosition: {
            bottom: 10,
        },
        noMargin: {
            margin: 0,
        },
        valueOrOptionsContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "382px",
        },
        radioButtonContainer: {
            display: "flex",
            gap: "10px",
            flexDirection: "row",
        },
        controlsContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },
    });
});
exports.default = useStyles;
