"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
exports.initialState = {
    id: null,
    trigger: null,
    conditions: [],
    handlers: [],
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case "ADD_HANDLER":
            return __assign(__assign({}, state), { handlers: state.handlers.slice().concat(action.payload) });
        case "UPDATE_HANDLER": {
            var handlers = state.handlers.slice();
            var handler = __assign(__assign({}, handlers[action.payload.index]), action.payload.handler);
            handlers[action.payload.index] = handler;
            return __assign(__assign({}, state), { handlers: handlers });
        }
        case "REMOVE_HANDLER": {
            return __assign(__assign({}, state), { handlers: __spreadArray(__spreadArray([], state.handlers.slice(0, action.payload), true), state.handlers.slice(action.payload + 1), true) });
        }
        case "ADD_TRIGGER":
            return __assign(__assign({}, state), { trigger: action.payload });
        case "REMOVE_TRIGGER":
            return __assign(__assign({}, state), { trigger: null, conditions: [], handlers: [] });
        case "UPDATE_TRIGGER": {
            var trigger = __assign(__assign({}, state.trigger), action.payload);
            return __assign(__assign({}, state), { trigger: trigger });
        }
        case "ADD_CONDITION":
            return __assign(__assign({}, state), { conditions: state.conditions.slice().concat(action.payload) });
        case "UPDATE_CONDITION": {
            var conditions = state.conditions.slice();
            var condition = __assign(__assign({}, conditions[action.payload.index]), action.payload.condition);
            conditions[action.payload.index] = condition;
            return __assign(__assign({}, state), { conditions: conditions });
        }
        case "REMOVE_CONDITION": {
            return __assign(__assign({}, state), { conditions: __spreadArray(__spreadArray([], state.conditions.slice(0, action.payload), true), state.conditions.slice(action.payload + 1), true) });
        }
        case "ADD_DROPDOWN_CONDITION_LABEL": {
            var conditions = state.conditions.slice();
            var condition = __assign({}, conditions[action.payload.index]);
            if (condition.type !== "dropdown_meets_condition") {
                return state;
            }
            condition.values = (condition.values || []).concat(action.payload.option.value);
            conditions[action.payload.index] = condition;
            return __assign(__assign({}, state), { conditions: conditions });
        }
        case "REMOVE_DROPDOWN_CONDITION_LABEL": {
            var conditions = state.conditions.slice();
            var condition = __assign({}, conditions[action.payload.index]);
            if (condition.type !== "dropdown_meets_condition") {
                return state;
            }
            var values = condition.values || [];
            var index = values.indexOf(action.payload.option.value);
            condition.values = __spreadArray(__spreadArray([], values.slice(0, index), true), values.slice(index + 1), true);
            conditions[action.payload.index] = condition;
            return __assign(__assign({}, state), { conditions: conditions });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
