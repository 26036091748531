"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.valuesAreValid = void 0;
var objectIterationMethods_1 = require("@utils/objectIterationMethods");
/* eslint-disable import/prefer-default-export */
var valuesAreValid = function (obj) {
    return (0, objectIterationMethods_1.getEntries)(obj).every(function (_a) {
        var key = _a[0], val = _a[1];
        if (key === "id")
            return true;
        return val !== null;
    });
};
exports.valuesAreValid = valuesAreValid;
