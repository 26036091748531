"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WORKSPACE_EVENTS = {
    JOIN_ROOM: "JOIN_ROOM",
    NODE_DATA_CHANGE: "NODE_DATA_CHANGE",
    NODE_HOVERING: "NODE_HOVERING",
    NODE_MOVED: "NODE_MOVED",
    NODE_NOT_HOVERING: "NODE_NOT_HOVERING",
    NODE_SAVE_DOCUMENTS: "NODE_SAVE_DOCUMENTS",
    ANALYSE_ALERTS: "ANALYSE_ALERTS",
    DESTROY: "DESTROY",
    EDGE_MOVED: "EDGE_MOVED",
    EDGE_LABEL_MOVED: "EDGE_LABEL_MOVED",
    EDGE_SOFT_REMOVE: "EDGE_SOFT_REMOVE",
    EDGE_STORE: "EDGE_STORE",
    EDGE_UPDATE: "EDGE_UPDATE",
    EDGE_UPDATE_POSITION: "EDGE_UPDATE_POSITION",
    EDGE_PATH_DRAGGED: "EDGE_PATH_DRAGGED",
    DUPLICATE_NODE_ON_EDGE: "DUPLICATE_NODE_ON_EDGE",
    EDGE_UPDATE_MULTIPLE: "EDGE_UPDATE_MULTIPLE",
    WORKSPACE_UNDO: "WORKSPACE_UNDO",
    WORKSPACE_REDO: "WORKSPACE_REDO",
    ELEMENTS_STORE: "ELEMENTS_STORE",
    ELEMENTS_UPDATE_POSITIONS: "ELEMENTS_UPDATE_POSITIONS",
    ELEMENTS_ALIGN: "ELEMENTS_ALIGN",
    WORKSPACE_FILE_IMPORT: "WORKSPACE_FILE_IMPORT",
    FILE_STORE: "FILE_STORE",
    ICON_STORE: "ICON_STORE",
    LOADING: "LOADING",
    NODE_REMOVE: "NODE_REMOVE",
    NODE_SOFT_REMOVE: "NODE_SOFT_REMOVE",
    NODE_STORE: "NODE_STORE",
    NODE_UPDATE: "NODE_UPDATE",
    NODE_UPDATE_MULTIPLE: "NODE_UPDATE_MULTIPLE",
    NODE_DUPLICATE_FROM_HANDLE: "NODE_DUPLICATE_FROM_HANDLE",
    STANDARD_NODE: "STANDARD_NODE",
    STICKY_STORE: "STICKY_STORE",
    STICKY_UPDATE: "STICKY_UPDATE",
    STICKY_UPDATE_SIZE: "STICKY_UPDATE_SIZE",
    TAB_REMOVE: "TAB_REMOVE",
    TAB_STORE: "TAB_STORE",
    TAB_UPDATE_INDEX: "TAB_UPDATE_INDEX",
    TAB_UPDATE_TITLE: "TAB_UPDATE_TITLE",
    TEXT_STORE: "TEXT_STORE",
    TEXT_UPDATE: "TEXT_UPDATE",
    UPDATE: "UPDATE",
    NODE_FIT_SIZE_TO_LABEL: "NODE_FIT_SIZE_TO_LABEL",
    USER_POSITION: "USER_POSITION",
    USER_POSITION_RELATIVE: "USER_POSITION_RELATIVE",
    TAB_REMOVE_WORKSPACE: "TAB_REMOVE_WORKSPACE",
    LEAVE_ROOM: "LEAVE_ROOM",
    REMOVE_CURSOR: "REMOVE_CURSOR",
    USER_EDIT_NODES: "USER_EDIT_NODES",
    USER_EDIT_EDGES: "USER_EDIT_EDGES",
    USER_FINISH_EDIT_EDGES: "USER_FINISH_EDIT_EDGES",
    USER_FINISH_EDIT_NODES: "USER_FINISH_EDIT_NODES",
    NODE_RESIZE: "NODE_RESIZE",
    NODE_RESIZE_MULTIPLE: "NODE_RESIZE_MULTIPLE",
    GROUP_NODE_STORE: "GROUP_NODE_STORE",
    GROUP_NODE_UPDATE: "GROUP_NODE_UPDATE",
    ATTACH_NODES: "ATTACH_NODES",
    DETACH_NODES: "DETACH_NODES",
    STEP_STORE: "STEP_STORE",
    STEP_REMOVE: "STEP_REMOVE",
    STEP_UPDATE_INDEX: "STEP_UPDATE_INDEX",
    STEP_UPDATE_TITLE: "STEP_UPDATE_TITLE",
    ELEMENT_UPDATE_DISPLAY_NAME: "ELEMENT_UPDATE_DISPLAY_NAME",
    IMPORT_FROM_CVR: "IMPORT_FROM_CVR",
    IMPORT_STRUCTURE: "IMPORT_STRUCTURE",
    NEW_BUILDING_BLOCKS_AND_TABS: "NEW_BUILDING_BLOCKS_AND_TABS",
    TRANSFORM_PROMPT_TO_STRUCTURE: "TRANSFORM_PROMPT_TO_STRUCTURE",
    DRILLDOWN_ACTION: "DRILLDOWN_ACTION",
    DRILLDOWN_CREATE: "DRILLDOWN_CREATE",
    DRILLDOWN_DISBAND: "DRILLDOWN_DISBAND",
    GET_EDITED_ELEMENTS_BY_USERS: "GET_EDITED_ELEMENTS_BY_USERS",
    USER_DISCONNECTS: "USER_DISCONNECTS",
    USER_TOGGLE_ACTIVE: "USER_TOGGLE_ACTIVE",
    UPDATE_CURSOR_ROOM: "UPDATE_CURSOR_ROOM",
    USER_RECONNECT: "USER_RECONNECT",
    USER_IMMUTABLE_GROUP_NODE: "USER_IMMUTABLE_GROUP_NODE",
    USER_MUTABLE_GROUP_NODE: "USER_MUTABLE_GROUP_NODE",
    POST_NODE_DOCUMENT: "POST_NODE_DOCUMENT",
    // Frame
    FRAME_LEAVE_ROOM: "FRAME_LEAVE_ROOM",
    WORKSPACE_FRAME_STORE: "WORKSPACE_FRAME_STORE",
    WORKSPACE_FRAME_UPDATE: "WORKSPACE_FRAME_UPDATE",
    WORKSPACE_FRAME_NODE_STORE: "WORKSPACE_FRAME_NODE_STORE",
    WORKSPACE_FRAME_NODE_DELETE: "WORKSPACE_FRAME_NODE_DELETE",
    WORKSPACE_FRAME_EDGE_STORE: "WORKSPACE_FRAME_EDGE_STORE",
    WORKSPACE_FRAME_EDGE_DELETE: "WORKSPACE_FRAME_EDGE_DELETE",
    WORKSPACE_FRAME_DELETE: "WORKSPACE_FRAME_DELETE",
    WORKSPACE_FRAME_DELETE_MULTIPLE: "WORKSPACE_FRAME_DELETE_MULTIPLE",
    WORKSPACE_FRAME_CURRENT_DELETED: "WORKSPACE_FRAME_CURRENT_DELETED",
    WORKSPACE_ANALYSE_OUTPUT_COMPLETE: "WORKSPACE_ANALYSE_OUTPUT_COMPLETE",
    WORKSPACE_ANALYSE_OUTPUT_FAILED: "WORKSPACE_ANALYSE_OUTPUT_FAILED",
    WORKSPACE_FRAME_ANALYSE_CONDITION_FETCH: "WORKSPACE_FRAME_ANALYSE_CONDITION_FETCH",
    WORKSPACE_FRAME_ANALYSE_CONDITIONS_ANALYSE: "WORKSPACE_FRAME_ANALYSE_CONDITIONS_ANALYSE",
    WORKSPACE_FRAME_ANALYSE_OUTPUT_FETCH: "WORKSPACE_FRAME_ANALYSE_OUTPUT_FETCH",
    WORKSPACE_FRAME_ANALYSE_FRAME_CREATE: "WORKSPACE_FRAME_ANALYSE_FRAME_CREATE",
    WORKSPACE_FRAME_ANALYSE_FAILED: "WORKSPACE_FRAME_ANALYSE_FAILED",
    WORKSPACE_FRAME_ANALYSE_INITIALIZE: "WORKSPACE_FRAME_ANALYSE_INITIALIZE",
    WORKSPACE_FRAME_ANALYSE_FINALIZE: "WORKSPACE_FRAME_ANALYSE_FINALIZE",
    WORKSPACE_FRAME_ANALYSE_SUBSEQUENT_DOWNLOAD: "WORKSPACE_FRAME_ANALYSE_SUBSEQUENT_DOWNLOAD",
    WORKSPACE_FRAMES_GET: "WORKSPACE_FRAMES_GET",
    // Z_INDEX
    BRING_TO_FRONT: "BRING_TO_FRONT",
    SEND_TO_BACK: "SEND_TO_BACK",
    BRING_FORWARD: "BRING_FORWARD",
    SEND_BACKWARD: "SEND_BACKWARD",
    // MIRROR
    MIRROR_MODE_BROADCAST: "MIRROR_MODE_BROADCAST",
    // comments
    WORKSPACE_COMMENT_STORE: "WORKSPACE_COMMENT_STORE",
    WORKSPACE_COMMENT_REMOVE: "WORKSPACE_COMMENT_REMOVE",
    IMANAGE_UPDATE_DOCUMENT_SUCCESS: "IMANAGE_UPDATE_DOCUMENT_SUCCESS",
    NEW_DOCUMENT_OPTIONS_BOUNCE: "NEW_DOCUMENT_OPTIONS_BOUNCE",
    GHOST_NODE_REMOVE: "GHOST_NODE_REMOVE",
    GHOST_NODE_STORE: "GHOST_NODE_STORE",
    TOGGLE_INCLUDE_LEGENDS_IN_PPTX: "TOGGLE_INCLUDE_LEGENDS_IN_PPTX",
    TOGGLE_SHOW_TITLE_OUTLINE_IN_PPTX: "TOGGLE_SHOW_TITLE_OUTLINE_IN_PPTX",
    TOGGLE_SHOW_BODY_OUTLINE_IN_PPTX: "TOGGLE_SHOW_BODY_OUTLINE_IN_PPTX",
    TOGGLE_INCLUDE_DUPLICATES_IN_ANALYSIS: "TOGGLE_INCLUDE_DUPLICATES_IN_ANALYSIS",
};
exports.default = WORKSPACE_EVENTS;
