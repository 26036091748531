"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a, _b;
    return ({
        table: {
            "& > div": {
                overflow: "auto",
            },
            "& table": (_a = {
                    "& td": {
                        wordBreak: "keep-all",
                    }
                },
                _a[theme.breakpoints.down("xl")] = {
                    "& td": {
                        maxHeight: 45,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                _a),
            marginBottom: 50,
        },
        profilePaper: {
            padding: theme.spacing(2),
            flexGrow: 1,
        },
        field: {
            width: "100%",
            marginBottom: 10,
        },
        flexRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: theme.spacing(2),
        },
        lottieContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        lottie: (_b = {
                width: 700 / 1.5,
                height: 700 / 1.5
            },
            _b[theme.breakpoints.down("xl")] = {
                width: 400 / 1.5,
                height: 400 / 1.5,
            },
            _b),
        createElementContainer: {
            padding: 16,
            width: "100%",
            overflowY: "auto",
        },
        nonSvgFill: {
            "& .rpv-core__viewer": {
                "& svg": {
                    fill: "none !important",
                },
            },
        },
    });
});
exports.default = useStyles;
