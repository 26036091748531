"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomerSlidePropertiesInPx = exports.CUSTOMER_SLIDE_DIMENSIONS = void 0;
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var PPTXNodeUtils_1 = require("@components/Workspace/Node/utils/PPTXNodeUtils");
var workspace_1 = require("@customTypes/reducers/workspace");
var converters_1 = require("@helpers/measuringUnits/converters");
var redux_1 = require("@hooks/redux");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var reactflow_1 = require("reactflow");
var POWERPOINT_SLIDE_DIMENSIONS = {
    widescreen: {
        width: 13.33,
        height: 7.5,
        titleBoxInInches: {
            x: 0.92,
            y: 0.4,
            width: 11.5,
            height: 1.45,
        },
        contentBoxesInInches: {
            left: {
                x: 0.92,
                y: 2,
                width: 5.67,
                height: 4.76,
            },
            right: {
                x: 6.75,
                y: 2,
                width: 5.67,
                height: 4.76,
            },
            full: {
                x: 0.92,
                y: 2,
                width: 11.5,
                height: 4.76,
            },
        },
    },
    onScreenShow: {
        width: 10,
        height: 5.63,
        titleBoxInInches: {
            x: 0.69,
            y: 0.3,
            height: 1.09,
            width: 8.63,
        },
        contentBoxesInInches: {
            full: {
                x: 0.69,
                y: 1.5,
                height: 3.57,
                width: 8.63,
            },
            left: {
                x: 0.69,
                y: 1.5,
                height: 3.57,
                width: 4.25,
            },
            right: {
                x: 5.06,
                y: 1.5,
                height: 3.57,
                width: 4.25,
            },
        },
    },
    letterPaper: {
        width: 11,
        height: 8.5,
        titleBoxInInches: {
            width: 8.63,
            height: 1.45,
            x: 0.69,
            y: 0.4,
        },
        contentBoxesInInches: {
            full: {
                width: 8.63,
                height: 4.76,
                x: 0.69,
                y: 2,
            },
            left: {
                height: 4.76,
                width: 4.25,
                x: 0.69,
                y: 2,
            },
            right: {
                height: 4.76,
                width: 4.25,
                x: 5.06,
                y: 2,
            },
        },
    },
    A4: {
        width: 10.83,
        height: 7.5,
        titleBoxInInches: {
            height: 1.45,
            width: 9.34,
            x: 0.74,
            y: 0.4,
        },
        contentBoxesInInches: {
            full: {
                height: 4.76,
                width: 9.34,
                x: 0.74,
                y: 2,
            },
            left: {
                height: 4.76,
                width: 4.6,
                x: 0.74,
                y: 2,
            },
            right: {
                height: 4.76,
                width: 4.6,
                x: 5.48,
                y: 2,
            },
        },
    }, // A4 paper
};
exports.CUSTOMER_SLIDE_DIMENSIONS = (_a = {},
    _a[constants_1.CUSTOMER.TRAVERS] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.A4), { titleBoxInInches: {
            height: 0.5,
            width: 9.55,
            x: 0.64,
            y: 0.57,
        }, contentBoxesInInches: {
            right: {
                height: 4.7,
                width: 4.63,
                x: 5.56,
                y: 2,
            },
            left: {
                height: 4.7,
                width: 4.63,
                x: 0.64,
                y: 2,
            },
            full: {
                height: 4.7,
                width: 9.55,
                x: 0.64,
                y: 2,
            },
        } }),
    _a[constants_1.CUSTOMER.ACCURA] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.ADVOKATGRUPPEN] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.BORCH] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.BECH_BRUUN] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.PWC] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.letterPaper), { textBoxBorder: {
            color: "#CCCCCC",
            style: "dashed",
        }, titleBoxInInches: {
            x: 0.5,
            y: 0.83,
            height: 1.2,
            width: 10,
        }, contentBoxesInInches: {
            full: {
                y: 2.34,
                x: 0.5,
                height: 5.33,
                width: 10,
            },
            right: {
                y: 2.34,
                x: 5.58,
                height: 5.33,
                width: 4.92,
            },
            left: {
                y: 2.34,
                x: 0.5,
                height: 5.33,
                width: 4.92,
            },
        } }),
    _a[constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen), { titleBoxInInches: {
            y: 0.315,
            x: 1.14,
            width: 11.02,
            height: 0.97,
        }, textBoxBorder: {
            style: "dashed",
            color: "#e5e5e5",
        }, contentBoxesInInches: {
            full: {
                y: 2.13,
                x: 1.14,
                height: 3.94,
                width: 11.02,
            },
            left: {
                y: 2.13,
                x: 1.14,
                height: 3.94,
                width: 5.2,
            },
            right: {
                y: 2.13,
                x: 6.97,
                height: 3.94,
                width: 5.2,
            },
        } }),
    _a[constants_1.CUSTOMER.TROWERS] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen),
    _a[constants_1.CUSTOMER.BIRD_BIRD] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen), { titleBoxInInches: {
            height: 0.79,
            width: 11.76,
            x: 0.79,
            y: 0.73,
        }, contentBoxesInInches: {
            right: {
                height: 4.93,
                width: 5.78,
                x: 6.76,
                y: 1.78,
            },
            left: {
                height: 4.93,
                width: 5.78,
                x: 0.79,
                y: 1.78,
            },
            full: {
                height: 4.93,
                width: 11.76,
                x: 0.79,
                y: 1.78,
            },
        } }),
    _a[constants_1.CUSTOMER.ROESGAARD] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen), { contentPaddingXInPx: 15, contentPaddingYInPx: 15, textBoxBorder: {
            color: "#000000",
            style: "dotted",
        }, titleBoxInInches: {
            x: 0.75,
            y: 1.05,
            width: 10.91,
            height: 0.94,
        }, contentBoxesInInches: {
            left: {
                x: 0.75,
                y: 1.99,
                width: 5,
                height: 4.69,
            },
            right: {
                x: 6.66,
                y: 1.99,
                width: 5,
                height: 4.69,
            },
            full: {
                x: 0.75,
                y: 1.99,
                width: 10.91,
                height: 4.69,
            },
        }, legendPositioningInInches: {
            marginX: 0.3125,
            marginY: 0.3125,
            float: "left",
        } }),
    _a[constants_1.CUSTOMER.MOALEM] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.A4), { defaultLabelBackground: "#F3F3ED", titleBoxInInches: {
            width: 9.02,
            height: 0.42,
            y: 0.62,
            x: 0.72,
        }, contentBoxesInInches: {
            right: {
                width: 4.21,
                height: 4,
                y: 1.79,
                x: 5.53,
            },
            left: {
                width: 4.21,
                height: 4,
                y: 1.79,
                x: 0.72,
            },
            full: {
                width: 9.02,
                height: 4,
                y: 1.79,
                x: 0.72,
            },
        } }),
    _a[constants_1.CUSTOMER.ALLEN_OVERY] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen), { titleBoxInInches: {
            x: 0.52,
            y: 0.74,
            width: 12.2,
            height: 0.89,
        }, contentBoxesInInches: {
            right: {
                x: 6.67,
                y: 1.9,
                width: 5.62,
                height: 4.76,
            },
            left: {
                x: 0.52,
                y: 1.9,
                width: 5.62,
                height: 4.76,
            },
            full: {
                x: 0.52,
                y: 1.9,
                width: 11.77,
                height: 4.76,
            },
        } }),
    _a[constants_1.CUSTOMER.SNELLMAN] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen),
    _a[constants_1.CUSTOMER.WONG] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.SCHJODT] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.LPA] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.DONATZKY] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.ADB] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.HJULMANDKAPTAIN] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.FOCUS] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.HAAVIND] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.LOU] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.MOLT_WENGEL] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.SUNDGAARD] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.GORRISSEN_FEDERSPIEL] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.onScreenShow),
    _a[constants_1.CUSTOMER.JURISTIC] = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen), { legendPositioningInInches: {
            marginX: 0.71,
            marginY: 0.6004,
            float: "left",
        } }),
    _a[constants_1.CUSTOMER.RSM] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen),
    _a[constants_1.CUSTOMER.HOMBURGER] = __assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen),
    _a.default = __assign(__assign({}, POWERPOINT_SLIDE_DIMENSIONS.widescreen), { defaultLabelBackground: "#FFFFFF", contentPaddingYInPx: 0, contentPaddingXInPx: 0, textBoxBorder: {
            color: "#000000",
            style: "dashed",
        }, legendPositioningInInches: {
            marginX: 0.3125,
            marginY: 0.3125,
            float: "right",
        } }),
    _a);
var getCustomerSlidePropertiesInPx = function (customer) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28;
    var defaultSlideProperties = exports.CUSTOMER_SLIDE_DIMENSIONS.default;
    var customerSlideProperties = exports.CUSTOMER_SLIDE_DIMENSIONS[customer !== null && customer !== void 0 ? customer : "default"];
    return {
        height: (0, converters_1.inch2px)(customerSlideProperties.height),
        width: (0, converters_1.inch2px)(customerSlideProperties.width),
        paddingX: (_a = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.contentPaddingXInPx) !== null && _a !== void 0 ? _a : defaultSlideProperties.contentPaddingXInPx,
        paddingY: (_b = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.contentPaddingYInPx) !== null && _b !== void 0 ? _b : defaultSlideProperties.contentPaddingYInPx,
        textBoxBorder: (_c = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.textBoxBorder) !== null && _c !== void 0 ? _c : defaultSlideProperties.textBoxBorder,
        titleBox: {
            x: (0, converters_1.inch2px)((_e = (_d = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.titleBoxInInches) === null || _d === void 0 ? void 0 : _d.x) !== null && _e !== void 0 ? _e : defaultSlideProperties.titleBoxInInches.x),
            y: (0, converters_1.inch2px)((_g = (_f = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.titleBoxInInches) === null || _f === void 0 ? void 0 : _f.y) !== null && _g !== void 0 ? _g : defaultSlideProperties.titleBoxInInches.y),
            width: (0, converters_1.inch2px)((_j = (_h = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.titleBoxInInches) === null || _h === void 0 ? void 0 : _h.width) !== null && _j !== void 0 ? _j : defaultSlideProperties.titleBoxInInches.width),
            height: (0, converters_1.inch2px)((_l = (_k = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.titleBoxInInches) === null || _k === void 0 ? void 0 : _k.height) !== null && _l !== void 0 ? _l : defaultSlideProperties.titleBoxInInches.height),
        },
        contentBoxes: {
            full: {
                x: (0, converters_1.inch2px)((_p = (_o = (_m = customerSlideProperties.contentBoxesInInches) === null || _m === void 0 ? void 0 : _m.full) === null || _o === void 0 ? void 0 : _o.x) !== null && _p !== void 0 ? _p : defaultSlideProperties.contentBoxesInInches.full.x),
                y: (0, converters_1.inch2px)((_s = (_r = (_q = customerSlideProperties.contentBoxesInInches) === null || _q === void 0 ? void 0 : _q.full) === null || _r === void 0 ? void 0 : _r.y) !== null && _s !== void 0 ? _s : defaultSlideProperties.contentBoxesInInches.full.y),
                height: (0, converters_1.inch2px)((_v = (_u = (_t = customerSlideProperties.contentBoxesInInches) === null || _t === void 0 ? void 0 : _t.full) === null || _u === void 0 ? void 0 : _u.height) !== null && _v !== void 0 ? _v : defaultSlideProperties.contentBoxesInInches.full.height),
                width: (0, converters_1.inch2px)((_y = (_x = (_w = customerSlideProperties.contentBoxesInInches) === null || _w === void 0 ? void 0 : _w.full) === null || _x === void 0 ? void 0 : _x.width) !== null && _y !== void 0 ? _y : defaultSlideProperties.contentBoxesInInches.full.width),
            },
            right: {
                x: (0, converters_1.inch2px)((_1 = (_0 = (_z = customerSlideProperties.contentBoxesInInches) === null || _z === void 0 ? void 0 : _z.right) === null || _0 === void 0 ? void 0 : _0.x) !== null && _1 !== void 0 ? _1 : defaultSlideProperties.contentBoxesInInches.right.x),
                y: (0, converters_1.inch2px)((_4 = (_3 = (_2 = customerSlideProperties.contentBoxesInInches) === null || _2 === void 0 ? void 0 : _2.right) === null || _3 === void 0 ? void 0 : _3.y) !== null && _4 !== void 0 ? _4 : defaultSlideProperties.contentBoxesInInches.right.y),
                height: (0, converters_1.inch2px)((_7 = (_6 = (_5 = customerSlideProperties.contentBoxesInInches) === null || _5 === void 0 ? void 0 : _5.right) === null || _6 === void 0 ? void 0 : _6.height) !== null && _7 !== void 0 ? _7 : defaultSlideProperties.contentBoxesInInches.right.height),
                width: (0, converters_1.inch2px)((_10 = (_9 = (_8 = customerSlideProperties.contentBoxesInInches) === null || _8 === void 0 ? void 0 : _8.right) === null || _9 === void 0 ? void 0 : _9.width) !== null && _10 !== void 0 ? _10 : defaultSlideProperties.contentBoxesInInches.right.width),
            },
            left: {
                x: (0, converters_1.inch2px)((_13 = (_12 = (_11 = customerSlideProperties.contentBoxesInInches) === null || _11 === void 0 ? void 0 : _11.left) === null || _12 === void 0 ? void 0 : _12.x) !== null && _13 !== void 0 ? _13 : defaultSlideProperties.contentBoxesInInches.left.x),
                y: (0, converters_1.inch2px)((_16 = (_15 = (_14 = customerSlideProperties.contentBoxesInInches) === null || _14 === void 0 ? void 0 : _14.left) === null || _15 === void 0 ? void 0 : _15.y) !== null && _16 !== void 0 ? _16 : defaultSlideProperties.contentBoxesInInches.left.y),
                height: (0, converters_1.inch2px)((_19 = (_18 = (_17 = customerSlideProperties.contentBoxesInInches) === null || _17 === void 0 ? void 0 : _17.left) === null || _18 === void 0 ? void 0 : _18.height) !== null && _19 !== void 0 ? _19 : defaultSlideProperties.contentBoxesInInches.left.height),
                width: (0, converters_1.inch2px)((_22 = (_21 = (_20 = customerSlideProperties.contentBoxesInInches) === null || _20 === void 0 ? void 0 : _20.left) === null || _21 === void 0 ? void 0 : _21.width) !== null && _22 !== void 0 ? _22 : defaultSlideProperties.contentBoxesInInches.left.width),
            },
        },
        legendPositioning: {
            marginX: (0, converters_1.inch2px)((_24 = (_23 = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.legendPositioningInInches) === null || _23 === void 0 ? void 0 : _23.marginX) !== null && _24 !== void 0 ? _24 : defaultSlideProperties.legendPositioningInInches.marginX),
            marginY: (0, converters_1.inch2px)((_26 = (_25 = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.legendPositioningInInches) === null || _25 === void 0 ? void 0 : _25.marginY) !== null && _26 !== void 0 ? _26 : defaultSlideProperties.legendPositioningInInches.marginY),
            float: (_28 = (_27 = customerSlideProperties === null || customerSlideProperties === void 0 ? void 0 : customerSlideProperties.legendPositioningInInches) === null || _27 === void 0 ? void 0 : _27.float) !== null && _28 !== void 0 ? _28 : defaultSlideProperties.legendPositioningInInches.float,
        },
    };
};
exports.getCustomerSlidePropertiesInPx = getCustomerSlidePropertiesInPx;
var usePowerPointFitter = function (mirror, PPTXView) {
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var customer = (0, constants_1.getCustomer)(user);
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var fitSelectedElements = (0, react_1.useCallback)(function () {
        var nodeElements = rfInstance.getNodes();
        var selectedNodes = nodeElements.filter(function (node) { return node.selected; });
        var edgeElements = rfInstance.getEdges();
        var selectedEdges = edgeElements.filter(function (edge) { return edge.selected; });
        var _a = (0, exports.getCustomerSlidePropertiesInPx)(customer), slideHeight = _a.height, slideWidth = _a.width, paddingX = _a.paddingX, paddingY = _a.paddingY, 
        // eslint-disable-next-line prefer-const
        contentBoxes = _a.contentBoxes;
        var _b = selectedNodes.reduce(function (acc, node) {
            // if the node has a parent, it can never be the most left, right, top or bottom node
            if (node.parentNode) {
                return acc;
            }
            var _a = node.position, x = _a.x, y = _a.y;
            var _b = node.data, width = _b.width, height = _b.height;
            acc.mostLeftX = Math.min(acc.mostLeftX, x);
            acc.mostTopY = Math.min(acc.mostTopY, y);
            acc.mostRightX = Math.max(acc.mostRightX, x + (width !== null && width !== void 0 ? width : 50));
            acc.mostBottomY = Math.max(acc.mostBottomY, y + (height !== null && height !== void 0 ? height : 50));
            return acc;
        }, {
            mostLeftX: Infinity,
            mostTopY: Infinity,
            mostRightX: -Infinity,
            mostBottomY: -Infinity,
        }), mostLeftX = _b.mostLeftX, mostTopY = _b.mostTopY, mostRightX = _b.mostRightX, mostBottomY = _b.mostBottomY;
        var nodeWidthSpan = mostRightX - mostLeftX;
        var nodeHeightSpan = mostBottomY - mostTopY;
        var offsetX = 0;
        var offsetY = 0;
        if (PPTXView === PPTXNodeUtils_1.PPTX_VIEW.standard) {
            var _c = contentBoxes.left, x = _c.x, y = _c.y, width = _c.width, height = _c.height;
            slideWidth = width;
            slideHeight = height;
            offsetX = x;
            offsetY = y;
        }
        else {
            console.log("contentBoxes", contentBoxes.full);
            var _d = contentBoxes.full, x = _d.x, y = _d.y, width = _d.width, height = _d.height;
            slideWidth = width;
            slideHeight = height;
            offsetX = x;
            offsetY = y;
        }
        var nodeWidthRatio = (slideWidth - paddingX) / nodeWidthSpan;
        var nodeHeightRatio = (slideHeight - paddingY) / nodeHeightSpan;
        var shouldScaleDown = nodeWidthRatio < 1 || nodeHeightRatio < 1;
        // largest ratio is the one that should be used
        var ratio = Math.min(nodeWidthRatio, nodeHeightRatio);
        if (shouldScaleDown) {
            nodeWidthSpan *= ratio;
            nodeHeightSpan *= ratio;
        }
        paddingX = slideWidth - nodeWidthSpan;
        paddingY = slideHeight - nodeHeightSpan;
        var nodeChanges = [];
        var changedEdges = new Set();
        var edgeFontSize = 10;
        selectedNodes.sort(function (a, b) {
            if (a.type === workspace_1.WElementVariant.GROUP && b.type !== workspace_1.WElementVariant.GROUP) {
                return -1;
            }
            if (a.type !== workspace_1.WElementVariant.GROUP && b.type === workspace_1.WElementVariant.GROUP) {
                return 1;
            }
            return 0;
        });
        selectedNodes.forEach(function (node) {
            // if the nodes are larger than the slide, scale them down
            var _a, _b;
            var adjustedRatio = shouldScaleDown ? ratio : 1;
            if (node.parentNode) {
                node.position.x *= adjustedRatio;
                node.position.y *= adjustedRatio;
            }
            else {
                node.position.x =
                    (node.position.x - mostLeftX) * adjustedRatio + offsetX + paddingX / 2;
                node.position.y =
                    (node.position.y - mostTopY) * adjustedRatio + offsetY + paddingY / 2;
            }
            nodeChanges.push({
                id: node.id,
                type: "position",
                position: { x: node.position.x, y: node.position.y },
            });
            var hasAttributesForScaling = ((_a = node.data.width) !== null && _a !== void 0 ? _a : false) && ((_b = node.data.height) !== null && _b !== void 0 ? _b : false);
            if (shouldScaleDown && hasAttributesForScaling) {
                // @ts-ignore
                node.data.width *= ratio;
                // @ts-ignore
                node.data.height *= ratio;
                // @ts-ignore
                if (node.data.fontSize) {
                    // @ts-ignore
                    node.data.fontSize *= ratio;
                }
                nodeChanges.push({
                    id: node.id,
                    type: "dimensions",
                    // @ts-ignore
                    dimensions: { width: node.data.width, height: node.data.height },
                    updateStyle: true,
                });
            }
        });
        if (shouldScaleDown) {
            selectedEdges.forEach(function (edge) {
                if (edge.data && edge.labelStyle && edge.labelStyle.fontSize) {
                    edgeFontSize = edge.labelStyle.fontSize * ratio;
                    edge.labelStyle.fontSize = edgeFontSize;
                    changedEdges.add(edge.id);
                }
            });
            rfInstance.setEdges(function (edges) {
                return edges.map(function (edge) {
                    if (changedEdges.has(edge.id)) {
                        return __assign(__assign({}, edge), { labelStyle: __assign(__assign({}, edge.labelStyle), { fontSize: edgeFontSize }) });
                    }
                    return edge;
                });
            });
        }
        rfInstance.setNodes(function (nodes) { return (0, reactflow_1.applyNodeChanges)(nodeChanges, nodes); });
        setTimeout(function () {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
        }, 100);
        // @ts-ignore
        dispatch((0, workspaceActions_websocket_1.saveAutoLayout)(selectedNodes, selectedEdges, mirror()));
    }, [rfInstance, customer, dispatch, mirror, location, PPTXView]);
    return { fitSelectedElements: fitSelectedElements };
};
exports.default = usePowerPointFitter;
