"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function mapper(nodes) {
    return nodes
        .filter(function (x) { return x.type === "horizontal" && !x.id.includes("end"); })
        .reverse()
        .map(function (node) {
        var tableNode = {
            id: node.id,
            date: node.data.date,
            time: node.data.time,
            endDate: node.data.otherDate,
            endTime: node.data.otherTime,
            title: node.data.label,
            description: node.data.description,
            note: node.data.note,
            documents: node.data.documents,
            people: node.data.persons,
            tags: node.data.tags,
        };
        return tableNode;
    });
}
exports.default = mapper;
