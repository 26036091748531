"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var applicationTheme_1 = require("../../../../styles/theme/applicationTheme");
var material_1 = require("@mui/material");
exports.default = (function (theme) {
    var _a;
    var existingTheme = (0, applicationTheme_1.theme)(theme);
    var components = existingTheme.components, rest = __rest(existingTheme, ["components"]);
    return (0, material_1.createTheme)(__assign(__assign({}, rest), { components: __assign(__assign({}, components), { 
            // @ts-ignore - it exists
            MUIDataTableHeadRow: {
                styleOverrides: {
                    root: {
                        borderTop: "1px solid rgba(224, 224, 224, 0.3)",
                        borderBottom: "1px solid rgba(240, 240, 240, 0.3)",
                    },
                },
            }, MUIDataTableHeadCell: {
                styleOverrides: __assign(__assign({}, (_a = components === null || components === void 0 ? void 0 : components.MUIDataTableHeadCell) === null || _a === void 0 ? void 0 : _a.styleOverrides), { contentWrapper: {
                        justifyContent: "center",
                    }, toolButton: {
                        margin: 0,
                    } }),
            } }) }));
});
