"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var d3_hierarchy_1 = require("d3-hierarchy");
var thumbnailWidth = 220;
var thumbnailHeight = 340;
var nodeStaticHeight = 340;
function convertToHorizontalTree(initialNodes, initialEdges, withThumbnails) {
    var _a, _b, _c, _d;
    var nodes = [];
    var edges = initialEdges;
    var spacing = 1;
    var mainTimelineTotalDocuments = 0;
    if (withThumbnails) {
        for (var i = 0; i < initialNodes.length; i++) {
            var checkNode = initialNodes[i];
            checkNode.position.x += thumbnailWidth * mainTimelineTotalDocuments;
            var numOfDocuments = 0;
            if (checkNode.data.documents) {
                numOfDocuments =
                    checkNode.data.documents.filter(function (doc) { var _a; return !["eml", "msg"].includes(((_a = doc.fileType) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || ""); }).length +
                        (checkNode.data.sameDateNodes || []).reduce(function (acc, n) {
                            return n.data.documents
                                ? acc +
                                    n.data.documents.filter(function (doc) { var _a; return !["eml", "msg"].includes(((_a = doc.fileType) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || ""); }).length
                                : acc;
                        }, 0);
            }
            if (numOfDocuments) {
                mainTimelineTotalDocuments += numOfDocuments - 1 >= 0 ? numOfDocuments - 1 : 0;
            }
        }
    }
    initialNodes.reverse();
    var _loop_1 = function (i) {
        var checkNode = initialNodes[i];
        if (!checkNode.children ||
            checkNode.type === "addItem" ||
            checkNode.type === "stickyNote") {
            nodes.push(checkNode);
            return "continue";
        }
        var node = checkNode;
        var hierarchy = (0, d3_hierarchy_1.hierarchy)(node);
        for (var j = 0; j < hierarchy.descendants().length; j++) {
            var descendant = hierarchy.descendants()[j];
            descendant.data.id = descendant.data.id
                ? "".concat(descendant.data.id)
                : "".concat(node.id, "-").concat(j);
            descendant.data.children = descendant.children;
        }
        var layout = (0, d3_hierarchy_1.tree)().nodeSize([
            500,
            nodeStaticHeight,
        ]);
        for (var j = 0; j < hierarchy.descendants().length; j++) {
            var descendant = hierarchy.descendants()[j];
            // @ts-ignore works
            descendant.children = descendant.data.data.expanded
                ? descendant.data.children
                : descendant.depth > 0
                    ? [
                        descendant.data.children[0],
                    ]
                    : undefined;
        }
        var root = layout(hierarchy);
        for (var j = 0; j < root.descendants().length; j++) {
            var descendant = root.descendants()[j];
            var x = descendant.y;
            var y = descendant.x ? descendant.x : nodeStaticHeight;
            if (descendant.depth === 0) {
                y = node.position.y + descendant.x;
            }
            descendant.x = x;
            descendant.y = y;
        }
        for (var j = 0; j < root.descendants().length; j++) {
            var descendant = root.descendants()[j];
            var depth = descendant.depth;
            if (depth === 1) {
                var y = nodeStaticHeight * spacing +
                    nodeStaticHeight * descendant.data.data.siblingSpacing;
                descendant.y = y;
            }
        }
        root.descendants().sort(function (a, b) { return a.depth - b.depth; });
        for (var j = 0; j < root.descendants().length; j++) {
            var descendant = root.descendants()[j];
            var depth = descendant.depth;
            if (depth > 1) {
                var parentY = (_a = descendant.parent) === null || _a === void 0 ? void 0 : _a.y;
                // eslint-disable-next-line no-continue
                if (!parentY)
                    continue;
                descendant.y =
                    parentY + nodeStaticHeight * descendant.data.data.siblingSpacing;
            }
        }
        if (withThumbnails) {
            for (var j = 0; j < root.descendants().length; j++) {
                var descendant = root.descendants()[j];
                var depth = descendant.depth;
                // depth > 0 meaning there will be a parent
                if (depth > 0) {
                    if ((_b = descendant.data.data.extraSpacing) === null || _b === void 0 ? void 0 : _b.thumbnailSpacing) {
                        descendant.y +=
                            nodeStaticHeight * descendant.data.data.extraSpacing.thumbnailSpacing;
                    }
                    // eslint-disable-next-line no-continue
                    if (!descendant.data.data.thumbnailVerticalSpacing)
                        continue;
                    if ("".concat(descendant.data.parent) === "".concat(descendant.data.data.root)) {
                        // @ts-ignore
                        descendant.x = descendant.parent.x + nodeStaticHeight;
                    }
                    else {
                        var numOfDocuments = 
                        // @ts-ignore
                        descendant.parent.data.data.documents.filter(function (doc) { var _a; return !["eml", "msg"].includes(((_a = doc.fileType) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || ""); }).length +
                            // @ts-ignore
                            (descendant.parent.data.data.sameDateNodes || []).reduce(function (acc, n) {
                                return n.data.documents
                                    ? acc +
                                        n.data.documents.filter(function (doc) {
                                            var _a;
                                            return !["eml", "msg"].includes(((_a = doc.fileType) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || "");
                                        }).length
                                    : acc;
                            }, 0);
                        descendant.x =
                            // @ts-ignore
                            descendant.parent.x +
                                nodeStaticHeight +
                                thumbnailWidth *
                                    // @ts-ignore
                                    (numOfDocuments - 1 >= 0
                                        ? // @ts-ignore
                                            numOfDocuments - 1
                                        : 0);
                    }
                    descendant.y += thumbnailHeight * descendant.data.data.thumbnailVerticalSpacing;
                }
            }
        }
        else {
            for (var j = 0; j < root.descendants().length; j++) {
                var descendant = root.descendants()[j];
                var depth = descendant.depth;
                // eslint-disable-next-line no-continue
                if (!((_c = descendant.data.data.extraSpacing) === null || _c === void 0 ? void 0 : _c.thumbnailSpacing))
                    continue;
                if (depth > 0) {
                    descendant.y +=
                        nodeStaticHeight * descendant.data.data.extraSpacing.thumbnailSpacing;
                }
            }
        }
        for (var j = 0; j < root.descendants().length; j++) {
            var descendant = root.descendants()[j];
            var depth = descendant.depth;
            if (depth > 0) {
                if ((_d = descendant.data.data.extraSpacing) === null || _d === void 0 ? void 0 : _d.stackedSpacing) {
                    descendant.y += descendant.data.data.extraSpacing.stackedSpacing;
                }
            }
        }
        var treeNodes = root.descendants().map(function (descendant) {
            var _a, _b, _c, _d, _e;
            var x = node.children ? node.position.x + descendant.x : descendant.x;
            var y = descendant.y;
            var result = __assign(__assign({}, descendant.data), { data: __assign(__assign(__assign({}, descendant.data.data), { depth: descendant.depth, hasChildren: descendant.data.children && true, numOfChildren: (_a = descendant.data.children) === null || _a === void 0 ? void 0 : _a.length, expanded: descendant.data.data.expanded }), (descendant.data.id !== node.id && {
                    lastIndex: descendant.children
                        ? descendant.children.some(function (child) { return "".concat(child.data.data.root) !== "".concat(descendant.data.id); })
                            ? descendant.children.length - 1
                            : descendant.children.length
                        : 0,
                    root: descendant.data.data.root,
                    parent: {
                        id: (_b = descendant.parent) === null || _b === void 0 ? void 0 : _b.data.id,
                        numOfChildren: (_d = (_c = descendant.parent) === null || _c === void 0 ? void 0 : _c.children) === null || _d === void 0 ? void 0 : _d.length,
                        documents: (_e = descendant.parent) === null || _e === void 0 ? void 0 : _e.data.data.documents.length,
                    },
                })), position: { x: x, y: y } });
            delete result.children;
            return result;
        });
        var treeEdges = root.links().map(function (link) { return ({
            id: "".concat(link.source.data.id, "-").concat(link.target.data.id),
            source: link.source.data.id,
            target: link.target.data.id,
            data: {},
            type: "customChildEdge",
        }); });
        nodes.push.apply(nodes, treeNodes);
        edges.push.apply(edges, treeEdges);
        if (node.id !== "addItem" && node.data.spacing) {
            spacing += node.data.spacing;
        }
    };
    for (var i = 0; i < initialNodes.length; i++) {
        _loop_1(i);
    }
    return {
        nodes: nodes.flat(),
        edges: edges.flat(),
    };
}
exports.default = convertToHorizontalTree;
